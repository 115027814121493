import Swiper from "swiper/bundle";
import 'swiper/css/bundle';
import sanDiegoUrl from './images/san-diego.png' // => or relative path


import createNavbarFunctionality from "./js/createNavbarFunctionality";

document.addEventListener("DOMContentLoaded", async () => {
    createNavbarFunctionality()
    const cityInput = document.getElementById('CityInput');
    const checkinInput = document.getElementById("checkin_date");
    const checkoutInput = document.getElementById("checkout_date");
    // const exploreMoreButton = document.querySelector('.explore-button');
    const parentList = document.getElementById('parentList');

    // Initialize the checkout date picker
    const endCalendar = flatpickr(checkoutInput, {
        // allowInput: true,
        disableMobile: "true",
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y",
        minDate: new Date().fp_incr(1), // Set the initial minDate to today
    });

    // Initialize the checkin date picker
    const startCalendar = flatpickr(checkinInput, {
        // allowInput: true,
        disableMobile: "true",
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y",
        minDate:  new Date().fp_incr(1),
        onChange: function (selectedDates, dateStr, instance) {
            const checkinDate = selectedDates[0];
            // Set the checkout date's minDate to one day after the selected checkin date
            endCalendar.clear();
            endCalendar.set('minDate', new Date(checkinDate).fp_incr(1));
            // Clear any previously selected checkout date
            // Open the checkout date picker
            // endCalendar.open();
        }
    });

    // exploreMoreButton.addEventListener('click', async function () {

    //     console.log("Explore More button clicked");
    //     const cityCode = document.getElementById('search_segment_hotel_jpd_code').value;
    //     const cityName = cityInput.value;
    //     const checkinDate = checkinInput.value;
    //     const checkoutDate = checkoutInput.value;

    //     if (!cityCode || !cityName || !checkinDate || !checkoutDate) {
    //         alert("Please make sure to select a city and dates before exploring more hotels.");
    //         return;
    //     }

    //     console.log(`Searching for hotels in ${cityName} (${cityCode}) from ${checkinDate} to ${checkoutDate}`);

    //     // First, fetch partner hotels
    //     let partnerHotels = [];
    //     try {
    //         const response = await fetch('https://cloud-function-gateway-5h5tiyyl.uc.gateway.dev/CheckPartnerHotels', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'x-api-key': 'AIzaSyBGpRrCe_DGVZnu8Bp61Rd6AlItJbr3mIM'
    //             },
    //             body: JSON.stringify({ "JPDCode": cityCode })
    //         });
    //         const data = await response.json();
    //         partnerHotels = data.filtered_hotels;
    //         console.log('Partner Hotels:', partnerHotels);  // Debug log
    //     } catch (error) {
    //         console.error('Error fetching partner hotels:', error);
    //     }

    //     // Fetch all hotels (including partner hotels if any)
    //     let allHotels = [];
    //     try {
    //         const response = await fetch('https://bookings-uat.40hammocks.org/availtransactions?path=hotelavail', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'x-api-key': 'rZA7EU3na31GWRUPAXfR1aekvcoGBZwtamyjvFKZ'
    //             },
    //             body: JSON.stringify({
    //                 "search_segment_hotel_start": checkinDate,
    //                 "search_segment_hotel_end": checkoutDate,
    //                 "hotel_codes_avail": partnerHotels.length ? partnerHotels : undefined
    //             })
    //         });
    //         const data = await response.json();
    //         allHotels = data.hotels || [];
    //         console.log('All Hotels:', allHotels);  // Debug log
    //     } catch (error) {
    //         console.error('Error fetching all hotels:', error);
    //     }

    //     // Update the UI with the fetched hotels
    //     let htmlContent = '';

    //     if (partnerHotels.length > 0) {
    //         htmlContent += '<h2>Partner Hotels</h2>';
    //         htmlContent += partnerHotels.map(hotel => `
    //             <div class="room-collection-item w-dyn-item w-col w-col-3" role="listitem">
    //                 <div class="room-card">
    //                     <a href="room-single.html?hotelCode=${hotel.Code}" class="room-image-wrap w-inline-block">
    //                         <img src="${hotel.Images.Image[0].FileName}" alt="Room Image" class="room-image" loading="lazy">
    //                     </a>
    //                     <div class="room-content-wrap">
    //                         <a href="room-single.html?hotelCode=${hotel.Code}" class="room-card-title">${hotel.HotelName}</a>
    //                         <div class="room-divider"></div>
    //                         <p class="paragraph-12 paragraph-card"><span class="text-span">UP TO 30% OFF</span> — 40H Group Rate</p>
    //                         <div class="card-overlay card-overlay-2"></div>
    //                     </div>
    //                     <p class="paragraph-13 card-city">${hotel.Zone.Name.split(",")[0].trim()}</p>
    //                 </div>
    //             </div>
    //         `).join('');
    //     }

    //     if (allHotels.length > 0) {
    //         htmlContent += '<h2>All Hotels</h2>';
    //         htmlContent += allHotels.map(hotel => `
    //             <div class="room-collection-item w-dyn-item w-col w-col-3" role="listitem">
    //                 <div class="room-card">
    //                     <a href="room-single.html?hotelCode=${hotel.Code}" class="room-image-wrap w-inline-block">
    //                         <img src="${hotel.Images.Image[0].FileName}" alt="Room Image" class="room-image" loading="lazy">
    //                     </a>
    //                     <div class="room-content-wrap">
    //                         <a href="room-single.html?hotelCode=${hotel.Code}" class="room-card-title">${hotel.HotelName}</a>
    //                         <div class="room-divider"></div>
    //                         <p class="paragraph-12 paragraph-card"><span class="text-span">UP TO 30% OFF</span> — 40H Group Rate</p>
    //                         <div class="card-overlay card-overlay-2"></div>
    //                     </div>
    //                     <p class="paragraph-13 card-city">${hotel.Zone.Name.split(",")[0].trim()}</p>
    //                 </div>
    //             </div>
    //         `).join('');
    //     } else {
    //         htmlContent += '<p>No Hotels Found</p>';
    //     }

    //     parentList.innerHTML = htmlContent;
    // });
});
 
let handleInitialFullPageLoader = ()=>{
    let visited = sessionStorage.getItem('shown')
    let loader = document.getElementById('homeFullPageLoader');
    if(!loader) return 
    let loaderImg = document.getElementById('homeFullPageLoaderImg');
    let body = document.getElementById('homeBody');

    if (!visited) loaderImg.style.display = 'block'
    body.style.overflow = 'hidden';
    window.addEventListener("load", () => {
        loader. style.opacity = '0'
        loaderImg.style.transform= "scale(0.9)"
        body.style.overflow = '';
        sessionStorage.setItem('shown', true);
        animateInitialHomediv()
        setTimeout(() => {
            document.getElementById('homeFullPageLoader'). style.display = 'none'
        }, 500);
    });
}
let animateInitialHomediv=()=>{
   // move animations from webflow file to here
}

handleInitialFullPageLoader(); 

document.addEventListener("DOMContentLoaded", () => {
    // const exploreMoreButton = document.querySelector('.explore-button');

    // exploreMoreButton.addEventListener('click', function() {
    //     const cityCode = document.getElementById('search_segment_hotel_jpd_code').value;
    //     const cityName = document.getElementById('CityInput').value;
    //     const checkinDate = document.getElementById('checkin_date').value;
    //     const checkoutDate = document.getElementById('checkout_date').value;

    //     if (cityCode && cityName && checkinDate && checkoutDate) {
    //         const url = `rooms-search.html?search_segment_hotel_jpd_code=${cityCode}&city=${cityName}&search_segment_hotel_start=${checkinDate}&search_segment_hotel_end=${checkoutDate}`;
    //         window.location.href = url;
    //     } else {
    //         alert("Please make sure to select a city and dates before exploring more hotels.");
    //     }
    // });
    // Initialize the end date picker first
    const endCalendar = flatpickr("#checkout_date", {
        // allowInput: true,
        disableMobile: "true",
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y",
        minDate: new Date().fp_incr(2)
    });

    // Initialize the start date picker
    flatpickr("#checkin_date", {
        // allowInput: true,
        disableMobile: "true",
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y",
        minDate: new Date().fp_incr(1),           
        onChange: function(selectedDates) {
            const checkinDate = selectedDates[0];
            // Set the minimum date of the end date picker to the day after the selected check-in date
            endCalendar.clear();
            endCalendar.set('minDate', new Date(checkinDate).fp_incr(1));
            // Clear the current end date to avoid selecting a date before the new min date
        }
    });
});

document.addEventListener('DOMContentLoaded', function() {
    const parentList = document.getElementById('parentList');
    const noHotelsMessage = document.getElementById('noHotelsMessage');
    
    // Function to check if there are no hotel results and display the message
    function checkNoHotels() {
      const hotelItems = parentList.querySelectorAll('.room-collection-item');
      if (hotelItems.length === 0) {
       noHotelsMessage && ( noHotelsMessage.style.display = 'block');
      } else {
        noHotelsMessage && (noHotelsMessage.style.display = 'none');
      }
    }

    // Call the function to check initially and after any updates
    checkNoHotels();

    // Assuming the hotel search updates dynamically, add a MutationObserver to watch for changes
    const observer = new MutationObserver(checkNoHotels);
    observer.observe(parentList, { childList: true });
  });
// document.addEventListener("DOMContentLoaded", () => {
    // const emailForm = document.getElementById('email-form');

    // Disable automatic form submission on field changes
//     emailForm.addEventListener('submit', function(event) {

//         console.log('clicked email form')
// event.preventDefault()
//         let emailErrorDisplay = document.getElementById("email-form-error-message")
//         console.log(emailErrorDisplay)
//     emailErrorDisplay.style.display = 'none'
//     emailErrorDisplay.innerHTML = ""

//         const inputs = emailForm.querySelectorAll('input');
//         console.log(inputs)
//         let allFilled = true;

//         inputs.forEach(function (input) {
//             console.log(input.value)
//             if (input.type !== 'submit' && input.value.trim() === '') {
//                 allFilled = false;
//                 console.log('false')
//             }
//         });

//         if (!allFilled) {
//             event.preventDefault(); // Prevent form submission if any field is empty
//             alert('Please complete all fields before submitting.');
//         }
//     });

// });

document.addEventListener("DOMContentLoaded", () => {
    const modal = document.getElementById("calendarModal");
    const span = document.getElementsByClassName("close")[0];

    span.onclick = function () {
        modal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target === modal) {
            modal.style.display = "none";
        }
    };

    function openCalendarModal(hotelCode) {
        modal.style.display = "block";
        flatpickr("#calendarInput", {
            // allowInput: true,
            disableMobile: "true",
            mode: "range",
            minDate: new Date().fp_incr(1),
            dateFormat: "Y-m-d",
            altInput: true,
            altFormat: "F j, Y",
            static: true,
            inline: true,
            onClose: function (selectedDates) {
                if (selectedDates.length === 2) {
                    const dateStart = selectedDates[0];
                    const dateEnd = selectedDates[1];
                    if (dateEnd <= dateStart) {
                        alert("Check-out date must be at least one day after check-in date.");
                        return;
                    }
                    const formattedDateStart = dateStart.toISOString().slice(0, 10);
                    const formattedDateEnd = dateEnd.toISOString().slice(0, 10);
                    window.location.href = `room-single.html?hotelCode=${hotelCode}&date_start=${formattedDateStart}&date_end=${formattedDateEnd}`;
                    modal.style.display = "none";
                }
            }
        }).open();
    }

    function openCalendarModalCity(city) {
        modal.style.display = "block";
        flatpickr("#calendarInput", {
            // allowInput: true,
            disableMobile: "true",
            mode: "range",
            minDate: new Date().fp_incr(1),
            dateFormat: "Y-m-d",
            altInput: true,
            altFormat: "F j, Y",
            static: true,
            inline: true,
            onClose: function (selectedDates) {
                if (selectedDates.length === 2) {
                    const dateStart = selectedDates[0];
                    const dateEnd = selectedDates[1];
                    if (dateEnd <= dateStart) {
                        alert("Check-out date must be at least one day after check-in date.");
                        return;
                    }
                    const formattedDateStart = dateStart.toISOString().slice(0, 10);
                    const formattedDateEnd = dateEnd.toISOString().slice(0, 10);
                    window.location.href = `rooms-search.html?search_segment_hotel_jpd_code=${city.code}&city=${city.name}&search_segment_hotel_start=${formattedDateStart}&search_segment_hotel_end=${formattedDateEnd}`;
                    modal.style.display = "none";
                }
            }
        }).open();
    }

    // hotel tiles for home page
    function createAndAppendHotelTiles(hotelsData, parentSelector) {
        const parentElement = document.querySelector(parentSelector);
        if (!parentElement) return;
        parentElement.innerHTML = '';
        hotelsData.forEach(hotel => {
            const roomCollectionItem = document.createElement('div');
            roomCollectionItem.className = 'room-collection-item w-dyn-item w-col w-col-3';
            roomCollectionItem.setAttribute('role', 'listitem');
            const roomCard = document.createElement('div');
            roomCard.className = 'room-card';
            const roomImageWrap = document.createElement('a');
            roomImageWrap.href = `room-single.html?hotelCode=${hotel.Code}`;
            roomImageWrap.className = 'room-image-wrap w-inline-block';
            const image = document.createElement('img');
            image.src = hotel.Images.Image[0].FileName;
            image.alt = 'Room Image';
            image.className = 'room-image';
            image.loading = 'lazy';
            roomImageWrap.appendChild(image);
            roomCard.appendChild(roomImageWrap);
            const roomContentWrap = document.createElement('div');
            roomContentWrap.className = 'room-content-wrap';

            // TITLE
            const roomTitle = document.createElement('a');
            roomTitle.href = `room-single.html?hotelCode=${hotel.Code}`;
            roomTitle.className = 'room-card-title';
            roomTitle.textContent = hotel.HotelName;
            roomContentWrap.appendChild(roomTitle);

            // DESCRIPTION
            // const roomDesc = document.createElement('p');
            // roomDesc.className = 'paragraph-12 paragraph-card';
            // roomDesc.innerHTML = '<span class="text-span">UP TO 30% OFF</span> — 40H Group Rate';
            // roomContentWrap.appendChild(roomDesc);


            //DESCRIPTION UPDATE
            const roomDescUpdated = document.createElement('div');
            roomDescUpdated.className = 'room-description';
            roomContentWrap.appendChild(roomDescUpdated)
          
            

            // PRICE/RATING
            const priceRatingSection = document.createElement('div');
            priceRatingSection.className = 'room-sub-description'
            roomDescUpdated.appendChild(priceRatingSection);

            const viewPrice = document.createElement('p');
            viewPrice.innerHTML='View Price'
            viewPrice.className ='roomDescriptionBolded'
            
            viewPrice.classList.add('roomDescriptionParagraph')
            priceRatingSection.appendChild(viewPrice);

            // const onlineRate = document.createElement('p');
            // onlineRate.classList.add('roomDescriptionParagraph')

            // onlineRate.innerHTML='Avg. Online Rate'
            // priceRatingSection.appendChild(onlineRate);
            // priceRatingSection.className = '';
            // view price
                // avg online rate

            //DIVIDER
            const roomDescriptionDivider = document.createElement('div');
            roomDescriptionDivider.className='roomDescriptionDivider'
            roomDescUpdated.appendChild(roomDescriptionDivider)

            // '/'

            // DISCOUNT
            const roomDiscountContainer = document.createElement('div');
            roomDiscountContainer.className = 'roomDiscountContainer'
            // upd to 30% off
            // 40h group rate
            roomDescUpdated.appendChild(roomDiscountContainer);

            const roomDiscout = document.createElement('p');
            roomDiscout.className ='roomDescriptionBolded'
            roomDiscout.classList.add('roomDescriptionParagraph')

            roomDiscout.innerHTML='Up to 30% off'
            roomDiscountContainer.appendChild(roomDiscout);
            const groupRate = document.createElement('p');
            groupRate.innerHTML='40H Group Rate'
            groupRate.className = 'text-span'          
              groupRate.classList.add('roomDescriptionParagraph')

            roomDiscountContainer.appendChild(groupRate);



            const roomDivider = document.createElement('div');
            roomDivider.className = 'room-divider';

            const roomOverlay= document.createElement('div');
            roomOverlay.className = 'card-overlay card-overlay-2';
            roomCard.appendChild(roomContentWrap);

            const cityName = hotel.Zone.Name.split(",")[0].trim();
            const cityWrap = document.createElement('p');
            cityWrap.className = 'paragraph-13 card-city';
            cityWrap.textContent = cityName;
            roomCard.appendChild(cityWrap);

            roomCard.appendChild(roomOverlay);
            roomCollectionItem.appendChild(roomCard);
            parentElement.appendChild(roomCollectionItem);
            roomCollectionItem.addEventListener('click', function (e) {
                e.preventDefault();
                openCalendarModal(hotel.Code);
            });
        });
        console.log('done making cards')
    }

    const sectionLoader = document.getElementById('sectionLoader');
    const roomSection = document.getElementById('roomSection');
    
    const parentList = document.getElementById('parentList');
    const noHotelsMessage = document.getElementById('noHotelsMessage');
    
    function fetchAndDisplayHotels(requestOptions, parentSelector) {
        sectionLoader.style.display = 'flex'; // Show loader
        roomSection.classList.add('loading'); // Add loading class to blur the section
    
        fetch("https://bookings-uat.40hammocks.org/staticdatatransactions?path=hotelcontent", requestOptions)
          .then(response => response.json())
          .then(data => {
            const hotels = data['soap:Envelope']['soap:Body'].HotelContentResponse.ContentRS.Contents.HotelContent;
            createAndAppendHotelTiles(hotels, parentSelector);
           
          })
          .catch(error => {
            console.log('error', error);
          
          }).finally(() =>{
            sectionLoader.style.display = 'none'; // Hide loader
            roomSection.classList.remove('loading'); // Remove loading class
          });
    }
    
    if (window.location.pathname.endsWith("index.html") || window.location.pathname === "/") {
        // Only fetch and display featured hotels on the home page
        const myHeaders = new Headers();
        myHeaders.append("x-api-key", "rZA7EU3na31GWRUPAXfR1aekvcoGBZwtamyjvFKZ");
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "hotel_codes_content": ["JP987858", "JP028875", "JP090923", "JP190992", "JP152040", "JP06163B", "JP081311", "JP211801", "JP782333", "JP062497", "JP02794P", "JP187033", "JP230051", "JP055775", "JP051506", "JP201449", "JP160835", "JP986422",
            "JP030694",
            "JP035465",
            "JP804009",
            "JP833777",
            "JP134665"]
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetchAndDisplayHotels(requestOptions, "#parentList");
    }

    function loadAndDisplayHotels(hotelCodes, parentSelector) {
       overlay.style.display = 'flex';
       roomSection.classList.add('loading');
        const myHeaders = new Headers();
        myHeaders.append("x-api-key", "rZA7EU3na31GWRUPAXfR1aekvcoGBZwtamyjvFKZ");
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ "hotel_codes_content": hotelCodes });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://bookings-uat.40hammocks.org/staticdatatransactions?path=hotelcontent", requestOptions)
            .then(response => response.json())
            .then(data => {
                const hotels = data['soap:Envelope']['soap:Body'].HotelContentResponse.ContentRS.Contents.HotelContent;
                createAndAppendHotelTiles(hotels, parentSelector);
            //    overlay.style.display = 'none';
           //     roomSection.classList.remove('loading');
            })
            .catch(error => {
                console.log('error', error);
          //      overlay.style.display = 'none';
          //      roomSection.classList.remove('loading');
            });
    }

    // function createAndAppendCityFeatured(cities, parentSelector) {
    //     const parentElement = document.querySelector(parentSelector);
    //     if (!parentElement) return;
    //     parentElement.innerHTML = '';
    //     cities.forEach(city => {
    //         const roomCollectionItem = document.createElement('div');
    //         roomCollectionItem.className = 'room-collection-item w-dyn-item w-col w-col-3';
    //         roomCollectionItem.setAttribute('role', 'listitem');
    //         const roomCard = document.createElement('div');
    //         roomCard.className = 'room-card';
    //         const roomImageWrap = document.createElement('a');
    //         roomImageWrap.href = `rooms-search.html?search_segment_hotel_jpd_code=${city.code}&city=${city.name}`;
    //         roomImageWrap.className = 'room-image-wrap w-inline-block';
    //         const image = document.createElement('img');
    //         image.src = city.image
    //         image.alt = 'Room Image';
    //         image.className = 'room-image';
    //         image.loading = 'lazy';
    //         roomImageWrap.appendChild(image);
    //         roomCard.appendChild(roomImageWrap);
    //         const roomContentWrap = document.createElement('div');
    //         roomContentWrap.className = 'room-content-wrap';
    //         const roomTitle = document.createElement('a');
    //         roomTitle.href = `rooms-search.html?search_segment_hotel_jpd_code=${city.code}&city=${city.name}`;
    //         roomTitle.className = 'room-card-title';
    //         roomTitle.textContent = city.name;
    //         const roomDesc = document.createElement('p');
    //         roomDesc.className = 'paragraph-12 paragraph-card';
    //         const roomOverlay= document.createElement('div');
    //         roomOverlay.className = 'card-overlay card-overlay-2';
    //         roomContentWrap.appendChild(roomTitle);
    //         roomContentWrap.appendChild(roomDesc);
    //         roomCard.appendChild(roomContentWrap);
    //         roomCard.appendChild(roomOverlay);
    //         roomCollectionItem.appendChild(roomCard);
    //         parentElement.appendChild(roomCollectionItem);
    //         roomCollectionItem.addEventListener('click', function (e) {
    //             e.preventDefault();
    //             openCalendarModalCity(city);
    //         });
    //     });
    // }

    const cities = [
        {
            name: "Napa Valley",
            code: 'JPD036002',
            image: 'https://keyassets.timeincuk.net/inspirewp/live/wp-content/uploads/sites/34/2021/07/Napa-Valley-920x609.jpg'
        },
        {
            name: "Los Angeles",
            code: 'JPD035809',
            image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Los_Angeles_with_Mount_Baldy.jpg/2880px-Los_Angeles_with_Mount_Baldy.jpg'
        },
        {
            name: "San Diego",
            code: 'JPD036292',
            image: sanDiegoUrl
        },
        {
            name: "Miami",
            code: 'JPD156888',
            image: 'https://www.cestujlevne.com/obrazky/54/45/45445-1440w.webp'
        },
        {
            name: "Austin",
            code: 'JPD034986',
            image: 'https://images.unsplash.com/photo-1557335200-a65f7f032602?q=80&w=2958&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        {
            name: "New York",
            code: 'JPD037079',
            image: 'https://images.unsplash.com/photo-1543716091-a840c05249ec?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        {
            name: "Las Vegas",
            code: 'JPD035751',
            image: 'https://content.paulreiffer.com/wp-content/uploads/2023/02/Featured-Image-Las-Vegas-Strip-Bellagio-Fountains-Paris-Hotel-Ballys-PH-High-Roller-Night-Cityscape-Viewpoint-Location-Paul-Reiffer-Nevada@2x.jpg'
        },
    ];

    const parentSelector = "#featured-marquee-gallery1";
    createAndAppendCityFeatured(cities, parentSelector);

function createAndAppendCityFeatured(cities, parentSelector) {
    const parentElement = document.querySelector(parentSelector);
    if (!parentElement) return;
    parentElement.innerHTML = '';

    cities.forEach(city => {
        const slide = document.createElement('div');
        slide.classList.add('swiper-slide');
        slide.innerHTML = `
            <div class="room-card">
                <a href="#" class="room-image-wrap w-inline-block">
                    <img src="${city.image}" alt="${city.name}" class="room-image" loading="lazy">
                </a>
                <div class="room-content-wrap">
                    <a href="#" class="room-card-title">${city.name}</a>
                    <div class="room-divider"></div>
                </div>
            </div>
        `;
        parentElement.appendChild(slide);

    
        slide.querySelector('.room-card').addEventListener('click', function (e) {
            e.preventDefault();
            openCalendarModalCity(city);
        });
    });

    // Initialize Swiper
    new Swiper ('.swiper-container', {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
        },
    });
}


    var swiper = new Swiper('.swiper-container-featured', {
        slidesPerView: 2,
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            1024: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 15,
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
        },
    });
}); 

